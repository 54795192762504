<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid"/> {{caption}}
      </slot>
    </CCardHeader>
    <CCardBody>
      <List ref="list" 
        showSearch
        :comboboxData=comboboxData
        :defaultSearchType=searchType
        :fields=fields
        :items.sync=permissionGroupList
        :loading.sync=loading
        sorter      
        :defaultSort=defaultSort
        showRegBtn
        itemsPerPageSelect
        :itemsPerPage=itemPerPage
        showDetails
        :showBtn =btnList
        menuKey=302
      >      
        <template #show_buttons="{item, index}">
          <td class="py-2" >
            <CButton
              size="sm" 
              square
              class="btn btn-primary"
              v-c-tooltip="'상세보기'"
              @click="detailItem(item, index)">
              <CIcon
                :content="$options.icons.cilMagnifyingGlass"
              />
            </CButton>
            <CButton
              v-if="item.groupType=='1' && $hasWritePermission('302')"
              square
              size="sm"
              class="btn btn-primary btn-lft-margin"
              v-c-tooltip="'삭제'"
              @click="deleteItem(item, index)"
            >
              <CIcon
                :content="$options.icons.cilTrash"
              />
            </CButton>
          </td>
        </template>  
        <template #no="{index}">
            <td class="py-2" >
              {{permissionGroupTotalCnt - ((pageNum -1) * itemPerPage +index)}}
            </td>
        </template>
        <template #appCnt="{index}">
            <td class="py-2">
              <div v-if="permissionGroupList[index]['allAppPermissionYn'] == '1'">
                 <CBadge color="info">ALL</CBadge>
              </div>
              <div v-else>
                {{permissionGroupList[index]['appCnt']}}
              </div>
            </td>
          </template>
        <!--아이템별 상세보기 재정의-->        
        <template slot="show_details">
            <div class="px-md-5">
            <CRow>
                <CCol sm="4" v-for="(items, subIndex) in menuList" :key="subIndex">
                  <CRow form class="form-group" >
                        <CCol tag="label" sm="3" class="col-form-label font-weight-bold">
                            {{items.menuNm}}
                        </CCol>
                    </CRow>
                    <CRow form class="form-group" v-for="(subItem, subIndex) in items.childrenItems" :key="subIndex">
                        <CCol sm="3" v-if="subItem.menuKey!=102">
                            {{subItem.menuNm}}
                        </CCol>
                        <CInputCheckbox
                            label="Read"
                            :value="subItem.menuKey"
                            :checked="subItem.readYn==1"
                            :inline="true"
                            :disabled="true"
                            v-if="subItem.menuKey!=102"
                            :custom="true"
                            />
                        <CInputCheckbox
                            label="Write"
                            value="write"
                            :checked="subItem.writeYn==1"
                            :disabled="true"
                            v-if="items.menuKey!=101"
                            :custom="true"
                            />
                        
                    </CRow>
                </CCol>
            </CRow>
            </div>
        </template>            
      </List>
      <page-link ref="page"
        :pageLinkCount=pagePerLink
        :listRowCount=itemPerPage
      />
    </CCardBody>
  </CCard>
</template>

<script>
import List from '@/views/common/List'
import PageLink from '@/views/common/PageLink'
import CIcon from '@coreui/icons-vue/src/CIconRaw'
import { cilMagnifyingGlass, cilPencil, cilTrash} from '@coreui/icons'

export default {
  name: 'PermissionGroupList',
  icons: { cilMagnifyingGlass, cilPencil, cilTrash},
  components: {
      List,
      PageLink,
      CIcon,
  },
  data() {
    return {
      searchType: 'groupNm',
      searchText: '',
       // 검색조건 설정
      comboboxData:[
        {label: '권한 그룹명', value: '1', name:'groupNm'}
      ],
      // 그리드 컬럼 설정
      fields:[
          { key: 'no', label:'No', _style: { width: '1%'} },
          { key: 'groupNm', label:'권한 그룹명', _style: { width: '20%'} },
          { key: 'appCnt', label:'제어 앱 수', _style: { width: '20%'} },
          { key: 'accountCnt', label:'할당 계정 수',  _style: { width: '20%'} },
          { key: 'regDate', label:'등록일', _style: { width: '20%'} },
          { 
             key: 'show_buttons', 
             label: '', 
             _style: 'width:13%', 
             sorter: false, 
             filter: false
           },
      ],
      btnList:[],
      pagePerLink: 5, // 한번에 보여줄 페이지번호 수
      defaultSort: '-regDate', // 기본 정렬 설정. 내림차순은 -groupNm
      pageNum: 1, // 페이지번호
      itemPerPage:8,  // 한페이지당 보여줄 데이터수
      loading:false,
      menuList:[],
       
    }
  },
  props: {
    caption: {
      default: '권한 그룹관리'
    }
  },
  computed: {
    permissionGroupList(){
      return this.$store.state.permissionGroup.permissionGroupList || []
    },
    permissionGroupTotalCnt(){
      return this.$store.state.permissionGroup.totalCnt
    }
  },
  created(){
  },
  mounted(){
    this.searchList()
  },
  methods: {
    async searchList(){
      let payload = this.$refs.list.getParams()
      this.loading=true 
      try {
        await this.$store.dispatch('permissionGroup/getPermissionGroupList', payload)
        this.$refs.page.reloadPagination(this.$store.state.permissionGroup.totalCnt)
        this.loading=false
      } catch(error){
        console.log(error)
        this.loading=false
      }
    }, 
    regItems(){
      this.$router.push({name: 'RegistPermissionGroup'})
    },
    async deleteItem(item, index){
      if(item.accountCnt > 0){
        alert('해당 권한에 할당된 계정이 있어 삭제가 불가합니다.');
        return
      }
      
      if(confirm('"'+item.groupNm+'" 그룹을 삭제하시겠습니까?')){
        let response = await this.$store.dispatch('permissionGroup/deletePermissionGroup', item.groupKey)
        if(response.status == 200){
          this.searchList()
        }
      }
    },
    detailItem(permissionGroupList, index){
      this.$router.push({path: '/admin/permissionGroup/permissionGroupInfo/'+permissionGroupList.groupKey})
    },
    async searchDetail(item, index){  
      try {
        let response = await this.$store.dispatch('permissionGroup/getMenuPermissionInfo', item.groupKey)
        this.menuList = response
        return item
       }catch(error){
         console.log(error)
       }
    },
  }
}
</script>